export default {
  LBL_LOADING_REPORT_INFORMATION: 'Loading Mission Control Center...',
  LBL_STATUS: 'Status',
  LBL_DEFAULT: 'Default',
  LBL_FILE: 'File',
  LBL_UPDATE: 'Update',
  LBL_SELECT: 'Select',
  LBL_UNAVAILABLE: 'Unavailable',
  LBL_YEAR: 'Year',

  // Actions
  LBL_CANCEL: 'Cancel',
  LBL_CANCEL_LER: 'LER Cancel',
  LBL_REJECT_LER: 'LER Reject',
  LBL_SCHEDULE: 'Schedule',
  LBL_CANCELLED: 'Cancelled',
  LBL_APPROVE_SAVE: 'Approve & Save',
  LBL_SAVE: 'Save',
  LBL_SAVE_UPDATES: 'Save Updates',
  LBL_REACTIVATE: 'Reactivate',
  LBL_REOPEN: 'Reopen',
  LBL_SUBMIT: 'Submit',
  LBL_TAKE_ACTION: 'Take Action',
  LBL_NEW: 'New',
  LBL_ADD: 'Add',
  LBL_CLONE: 'Clone',
  LBL_YES: 'Yes',
  LBL_NO: 'No',
  LBL_YES_DELETE: 'Yes, Delete',
  LBL_CONFIRM: 'Confirm',
  LBL_DELETE: 'Delete',
  LBL_DELETE_DRAFT: 'Delete Draft',
  LBL_DOWNLOAD: 'Download',
  LBL_FILTERS: 'Filters',
  LBL_UPLOAD: 'Upload',
  LBL_UPLOAD_VISITOR_LIST: 'Upload Visitor List',
  LBL_ACTION: 'Action',
  LBL_CLEAR_SEARCH: 'Clear Search',
  LBL_CLOSE: 'Close',
  LBL_OPEN: 'Open',
  LBL_REQUEST_KEY: 'Request Key',
  LBL_REQUEST_REJECT: 'Reject Request',
  LBL_REQUEST_APPROVE: 'Approve Request',
  LBL_REQUEST_UPDATE: 'Update Request',
  LBL_VALIDATE_AVAILABILITY: 'Validate Availability',
  LBL_MORE_DETAILS: 'More Details',
  LBL_GO_BACK: 'Go Back',
  LBL_EDIT: 'Edit',
  LBL_OKAY: 'OK',
  LBL_BROWSE: 'Browse',
  LBL_NOTES: 'Note',
  LBL_EXAMPLE: 'Example',
  LBL_ADD_DOCUMENTS: 'Add Documents',
  LBL_FORWARD: 'Forward',
  LBL_ENTER: 'Enter',
  LBL_COMPLETE: 'Complete',
  LBL_COMPLETED: 'Completed',
  LBL_INCOMPLETE: 'Incomplete',
  LBL_MARK_COMPLETED: 'Mark as completed',
  LBL_SEND_EMAIL: 'Send Email',
  LBL_EMAIL_VERSION: 'Email Version / Print',
  LBL_SEND_EMAIL_SYSTEM_CHANGE: 'Send System Change Email',
  LBL_ID: 'ID',
  LBL_COMPARE_SELECTED: 'Compare selected',
  LBL_VIEW: 'View',
  LBL_REMOVE: 'Remove',
  LBL_PRINT: 'Print',
  LBL_SET_STUDY: 'Set to Study',
  LBL_SEACH_VIEW_RECORDS: 'Search to view records',
  LBL_CNCL: 'CNCL',
  LBL_RSUB: 'RSUB',
  LBL_REFRESH: 'Refresh',
  LBL_BYPASS_EMAIL: 'Bypass Email',
  LBL_SEND_NOW: 'Send Now',

  // Tabs
  LBL_HOME: 'Home',
  LBL_ALL: 'All',
  LBL_PENDING_REQUEST: 'Pending Request',
  LBL_APPROVED: 'Approved',
  LBL_REJECTED: 'Rejected',
  LBL_EMPLOYEE: 'Employee',
  LBL_CONTRACTOR: 'Contractor',
  LBL_SEARCH: 'Search',

  // Filters
  LBL_NAME_SEARCH: 'Name',
  LBL_KEY_HOLDER_SEARCH: 'Key Holder (Employee ID)',
  LBL_SUPERVISOR_MNGR_DROPDOWN: 'Supervisor/Mgr. Empl. ID',
  LBL_SEARCH_NAME: 'Search Name',
  LBL_JOB_NUMBER: 'Job Number',

  // Employee Details
  LBL_EMPLOYEE_DEPARTMENT: 'Department',
  LBL_EMPLOYEE_JOB_TITLE: 'Title',
  LBL_EMPLOYEE_COMPANY: 'Company',

  // User Information
  LBL_LOADING_USER_INFORMATION: 'Loading User Information...',
  LBL_NAME: 'Name',
  LBL_FULLNAME: 'Full Name',
  LBL_USERNAME: 'Username',
  LBL_CONTACT_NUMBER: 'Contact Number',
  LBL_AGE: 'Age',
  LBL_UPDATED_AT: 'Updated At',
  LBL_CREATED_AT: 'Submitted On',
  LBL_UPDATED_BY: 'Updated By',
  LBL_DATE_MODIFIED: 'Date Modified',

  // Address Information
  LBL_CITY: 'City',
  LBL_STATE: 'State',
  LBL_ZIPCODE: 'ZipCode',
  LBL_GPS: 'GPS',
  LBL_TELEPHONE: 'Telephone',
  LBL_SUB_DIST_CODE: 'Sub Dist Code',
  LBL_DISTRICTS: 'Districts',

  // Equipment Type
  LBL_EQUIPMENT: 'Equipment',
  LBL_EQUIPMENT_TYPE: 'Equipment Type',
  LBL_EQUIPMENT_TYPE_NAME: 'Equipment Type Name',
  LBL_EQUIPMENT_TYPE_DESCRIPTION: 'Equipment Type Description',
  LBL_EQUIPMENT_VOLTAGE: 'Equipment Voltage',
  LBL_DOWNLOAD_TABLE_ID: 'Equipment Type - ID',
  LBL_EQUIPMENT_NAME: 'Equipment Name',
  // Key Request
  LBL_REQUEST_ID_TYPE: 'Request ID/Type',
  LBL_REQUEST_FOR: 'Requested For',
  LBL_REQUEST_BY: 'Requested By',
  LBL_REQUEST_DATE: 'Requested Date',
  LBL_KEY_HOLDER: 'Key Holder',
  LBL_SUPERVISOR_MANAGER: 'Supervisor/Manager',
  LBL_KEY_ADMIN_lOCATION: 'Key Admin Location',
  LBL_KEY_TYPE: 'Key Type',
  LBL_ISSUANCE_REASON: 'Issuance Reason',
  LBL_ASSIGN_KEY_NUMBER: 'Assign Key Number',

  LBL_KEY_REQUEST_DEMAND: 'Issue Key on Demand',
  LBL_KEY_REQUEST_KEY_HOLDER_EMP_ID: 'Key Holder Employee ID',
  LBL_KEY_REQUEST_KEY_TYPE: 'Key Type',
  LBL_KEY_REQUEST_INSSUANCE_REASON: 'Issuance Reason',
  LBL_KEY_REQUEST_ASSIGN_KEY_NUMBER: 'Assign Key Number',
  LBL_ATTACH_APPROVER_EMAIL: 'Attach Approver Email',
  LBL_SUBSTATION_ENTRY_TRAINING: 'Substation Entry Training',
  LBL_AUTH_TRAINING: 'Authorization Training',
  LBL_MCC_APP_KEY_REQUEST: 'MCC Application Key Request',

  // Facility
  LBL_FACILITY_STATUS: 'Facility Status',
  LBL_BES_FACILITY: 'BES Facility',
  LBL_OFF_SYSTEM: 'Off System',
  LBL_SPECIAL_CONCERNS: 'Special Concerns:',
  LBL_BLACK_START: 'Black Start',
  LBL_DESCRIPTION: 'Description',
  LBL_BLACK_START_PATH: 'Blackstart Path',

  LBL_SUBSTATIONS: 'Substations',
  LBL_SUBSTATION: 'Substation',
  LBL_TYPE: 'Type',
  LBL_VOLTAGE: 'Voltage',
  LBL_KV: 'KV',
  LBL_FACILITY_NAME: 'Facility Name',
  LBL_FACILITY_TYPE: 'Facility Type',
  LBL_BES: 'BES',
  LBL_EMAIL_TYPE: 'Email Type',
  LBL_NOTIFICATION_EMAIL: 'Notification Email',
  LBL_APPROVAL_EMAIL: 'Approval Email',

  LBL_ID_NAME: 'ID - Name',
  LBL_LOAD_MV: 'Load (MW)',
  LBL_COUNT: 'Count',
  LBL_LOAD: 'Load',
  LBL_LOADING: 'Loading',

  LBL_FACILITY: 'Facility',
  LBL_FACILITIES: 'Facilities',
  LBL_CAISO: 'CAISO TR IDs',
  LBL_SWITCH_TR: 'SWITCH TR IDs',
  LBL_EMS: 'EMS IDs',

  LBL_SUB_DISTRICT_ID: 'Sub District ID',
  LBL_POLE_ID: 'Pole ID',
  LBL_SWITCH_DESCRIPTION: 'Switch Description',

  LBL_SUBSTATION_ID: 'Substation ID',
  LBL_SUBSTATION_NAME: 'Substation Name',
  LBL_SUBSTATION_NAME_EDITVIEW: 'Name',
  LBL_SUBSTATION_EDITVIEW: 'Substation',
  LBL_SUBSTATION_LINE_ADDRESS_1: 'Substation Address Line 1',
  LBL_SUBSTATION_LINE_ADDRESS_2: 'Substation Address Line 2',
  LBL_SUBSTATION_ACTIVITY: 'Activity',
  LBL_SUBSTATION_TIME_IN: 'Time In',
  LBL_SUBSTATION_TIME_ON: 'Time Out',
  LBL_SUBSTATION_PURPOSE: 'Purpose',
  LBL_SUBSTATION_VISITORS: 'Visitors (Separated by comas)',
  LBL_SUBSTATION_UPLOAD_VISITORS: 'Upload Visitor List',
  LBL_SUBSTATION_VISITORS_UPLOADED_LIST: 'Visitors Uploaded List',
  LBL_EMPLOYEE_NAME_VISITORS: 'Employee Name and Visitors',
  LBL_IN_DATE: 'In Date',
  LBL_OUT_DATE: 'Out Date',
  LBL_VISITORS: 'Visitors',
  LBL_OUT: 'Out',
  LBL_BACK: 'Back',
  LBL_CHECKED_IN_BY: 'Checked In by',
  LBL_CHECKED_OUT_BY: 'Checked Out by',

  LBL_EQUIPMENT_ID: 'Equipment ID',
  LBL_SWITCH_ID: 'Switch ID',
  LBL_POSITION: 'Position',
  LBL_EMS_ID: 'EMS ID',
  LBL_TOP: 'TOP',
  LBL_STATION: 'Station',
  LBL_BUS_NAME: 'BUS Name',

  // User Groups
  LBL_ADD_GROUP: 'Add Group',
  LBL_EDIT_GROUP: '{0} - Edit',
  LBL_DELETE_GROUP: '{0} - Remove',
  LBL_GROUP_NAME: 'Group Name',
  LBL_GROUP_EMAIL: 'Group Email',
  LBL_DETAILS: 'Details',
  LBL_NAME_ID: 'Name/ID',
  LBL_GROUP_ADDED_MEMBERS: 'Added Group Members',
  LBL_GROUP_NEW: 'New Group',
  LBL_GROUP_MEMBERS: 'Group Members',
  LBL_GROUP_EMP_NAME: 'Employee Name',
  LBL_GROUP_EMP_ID: 'Employee ID',
  LBL_GROUP_EMP_EMAIL: 'Employee Email',
  LBL_GROUP_NO_MEMBERS: 'No members to display',

  LBL_KEY_REQUESTER: 'Key Requester',
  LBL_KEY_HOLDER_EMPLOYEE_ID: 'Key Holder Employee ID',
  LBL_KEY_REQUEST_TYPE: 'Request Type',
  LBL_KEY_ADMIN_MAIL_LOCATION: 'Key Admin Mail Location',
  LBL_KEY_SUPERVISOR_MANAGER_ID: 'Supervisor/Manager ID',
  LBL_KEY_ISSUED_TO: 'Key Issued to',
  LBL_KEY_SUPERVISOR_MANAGER: 'Supervisor/Manager',
  LBL_KEY_ADMIN_LOCATION: 'Key Admin Location',
  LBL_KEY_ISSUANCE_REASON: 'Issuance Reason',
  LBL_KEY_STATUS: 'Key Status',
  LBL_KEY_ISSUE_DATE: 'Issue Date',
  LBL_KEY_END_DATE: 'End Date',
  LBL_KEY_STATUS_DATE: 'Status Date',

  // Key Request Contractor
  LBL_KEY_REQUEST_CONTRACTOR_FIRM_NAME: 'Firm Name',
  LBL_KEY_REQUEST_CONTRACTOR_EMP_ID: 'Contact Employee ID',
  LBL_KEY_REQUEST_CONTRACTOR_KEY_TYPE: 'Key Type',
  LBL_KEY_REQUEST_CONTRACTOR_KEY_NO: 'Starting Key Number',
  LBL_KEY_REQUEST_CONTRACTOR_NO_KEY: 'Number of Keys',
  LBL_KEY_REQUEST_CONTRACTOR_VALIDATE_BTN: 'Validate Availability',

  LBL_EMPLOYEE_ID: 'Employee ID',
  LBL_EMPLOYEE_NAME: 'Name',
  LBL_EMPLOYEE_ROLE: 'Role',
  LBL_EMPLOYEE_TITLE: 'Title',
  LBL_EMPLOYEE_PHONE: 'Phone Number',
  LBL_EMPLOYEE_EMAIL: 'Email',
  LBL_EMPLOYEE_NUMBER: 'Employee Number',
  LBL_CSV_EMPLOYEE_NAME: 'Equipment Name',
  LBL_CSV_EMPLOYEE_DESCRIPTION: 'Equipment Description',

  // Job Code Rules
  LBL_JOB_CODE_RULE_ADD: 'Add New Rule',
  LBL_JOB_CODE_RULE_CODE: 'Job Code',
  LBL_JOB_CODE_RULE_TITLE: 'Job Title',
  LBL_JOB_CODE_RULE_NEW_MEDECO: 'New Medeco',
  LBL_JOB_CODE_RULE_OLD_MEDECO: 'Old Medeco',
  LBL_JOB_CODE_RULE_THIRTY_KEY: '30 Key',
  LBL_JOB_CODE_RULE_FOURTY_KEY: '40 Key',
  LBL_JOB_CODE_RULE_FF_KEY: 'FF Key',
  LBL_JOB_CODE_RULE_AUTH_TRAIN: 'Auth Training',
  LBL_JOB_CODE_RULE_SUB_ENTRY: 'Sub Entry',
  LBL_JOB_CODE_RULE_CODE_TITLE: 'Job Code/Job Title',
  LBL_JOB_CODE_RULE_KEY_RULES: 'Key Rules',
  LBL_JOB_CODE_RULE_TRAIN_RULES: 'Training Rules',
  LBL_JOB_CODE_STATUS: 'Job Code Status',
  LBL_ACTIVE_JOB_CODE: 'Active Job Code',
  LBL_INACTIVE_JOB_CODE: 'Inactive Job Code',

  // Issued Keys
  LBL_KEY_DETAILS: 'Key Details',
  LBL_ISSUED_KEY: 'Key',
  LBL_ISSUED_KEY_STATUS: 'Key Status',
  LBL_ISSUED_KEY_HOLDER_TYPE: 'Key Holder Type',
  LBL_ISSUED_KEY_TO: 'Key Issued To',
  LBL_ISSUED_KEY_MANAGER: 'Manager/ID',
  LBL_STATUS_KEY_DATE: 'Status Date',
  LBL_ISSUED_KEY_END_DATE: 'End Date',
  LBL_ISSUED_BY: 'Issued By',

  // My Request View
  LBL_REQUEST_ID: 'Request ID',
  LBL_REQUEST_STATUS: 'Request Status',
  LBL_KEY_NUMBER: 'Key Number',
  LBL_KEY_HOLDER_TYPE: 'Key Holder Type',
  LBL_ACTIONS: 'Actions',
  LBL_MANAGE: 'Manage',
  LBL_EMPLOYEE_STATUS: 'Employee Status',

  // TSO/OSS Employee List
  LBL_TSO_OSS: 'TSO/OSS',
  LBL_TSO_OSS_EMPLOYEE_NAME: 'Employee Name / ID',
  LBL_TSO_OSS_EMPLOYEE_TITLE: 'Title',
  LBL_TSO_OSS_EMPLOYEE_ROLE_TITLE: 'Role/Title',
  LBL_TSO_OSS_EMPLOYEE_ADD: 'Add Employee',
  LBL_TSO: 'TSO',
  LBL_OSS: 'OSS',

  // Workgroups
  LBL_WORK_GROUP_MANAGE: 'Manage Workgroups',
  LBL_WORK_GROUP_MAIL: 'Mail Distribution',
  LBL_WORK_GROUP_SUBGROUPS: 'Subgroups',
  LBL_WORK_GROUP_SUBGROUP: 'Subgroup',
  LBL_WORK_GROUP: 'Work Group',
  // Add/Edit form in workgroups
  LBL_EDIT_WORK_GROUP: 'Edit Work Group',
  LBL_ADD_WORK_GROUP: 'Add Work Group',
  LBL_EMAIL_DISTRIBUTION: 'Email Distribution',
  LBL_EMAIL_DISTRIBUTION_LIST: 'Email Distribution List',
  LBL_EMAIL_NO_EMAIL_DISTRIBUTION_ADDED: 'No email distribution added',
  LBL_EMAIL_NO_SUBGROUP_ADDED: 'No subgroups added',
  LBL_WORK_GROUP_SUBGROUP_NAME: 'Subgroup Name',
  LBL_EMAILS: 'Email(s)',
  LBL_EMAILS_MEMBERS: 'Email(s) / Members',
  // Real Time Log
  LBL_TODAY: 'Today',
  LBL_MY_WATCH_LIST: 'My Watch List',
  LBL_WHOLE_DAY: 'Last 24 hrs',
  LBL_USER: 'User',
  LBL_LOG_ENTRY: 'Log Entry',
  LBL_DATE_RANGE: 'Date Range',
  LBL_VIEW_RECORDS: 'Search to view records',
  LBL_VIEW_DETAIL: 'View Details',
  LBL_SEARCH_ENTRY:
    'Please select or enter the search criteria and click on the search button to see the results',
  LBL_BPOR: 'BPOR',
  LBL_TRIPS_TARGETS: 'Trips and Targets',
  LBL_ADD_ADDITIONAL_LOG: 'Add Additional Log',
  LBL_ADDITIONAL_LOGS: 'Additional Logs',
  LBL_SEND_FORCED_OUTAGE_CONFIRM:
    'Send the email notification for this log? Or Skip this and send it later?',
  LBL_SEND_FORCED_OUTAGE_CONFIRM_TITLE: 'Send Forced Outage Notification',
  LBL_BYPASS_FORCED_OUTAGE_EMAIL_CONFIRM_TITLE:
    'Bypass Forced Outage Notification',
  LBL_BYPASS_FORCED_OUTAGE_EMAIL_CONFIRM:
    'Are you sure you want to completely bypass the email notification?',

  // OC Pending Actions Filter
  LBL_DATE_FROM: 'From',
  LBL_DATE_TO: 'To',

  // Trouble Tickets Filter
  LBL_LINE_SUBSTATION: 'Line/Substation',
  LBL_DUE_DATE_RANGE: 'Due Date Range',
  LBL_COMMETS_RESOLUTION: 'Comments/Resolution',
  LBL_REQUEST_TYPE: 'Request Type',
  // Trouble Ticket
  LBL_LINE: 'Line',
  LBL_TROUBLE_TICKET: 'Trouble Ticket',
  LBL_TICKET_INFORMATION: 'Ticket Information',
  LBL_FACILITY_INFORMATION: 'Facility Information',
  LBL_TICKET_STATUS: 'Performed Work / Ticket Status',
  LBL_INFORMATION_REPORTED: 'Information Reported',
  LBL_SUB_GROUP: 'Sub Group',
  LBL_ASSIGNED_TO: 'Assigned To',
  LBL_BEGIN_DATE: 'Begin Date',
  LBL_DUE_DATE: 'Due Date',
  LBL_ETR_DATE: 'ETR Date',
  LBL_PRIORITY: 'Priority',
  LBL_FILE_NAME: 'File Name',
  LBL_UPLOADED_DATE: 'Uploaded Date',
  LBL_UPLOADED_BY: 'Uploaded By',
  LBL_COMMENTS_RESOLUTION: 'Comments/Resolution',
  LBL_COMMENTS_AND_RESOLUTION: 'Comments and Resolution',
  LBL_MARKED_AS: 'Marked as',
  LBL_TROUBLE_TICKET_ID: 'Trouble Ticket ID',
  LBL_TROUBLE_TICKET_DOCUMENTS: 'Trouble Ticket Documents',
  LBL_ETR: 'ETR',
  LBL_PARTICIPANTS: 'Participants',
  LBL_TROUBLE_TICKET_ID_DESC: 'ID/Description',
  LBL_TROUBLE_TICKET_STATUS: 'Status',
  LBL_TROUBLE_TICKET_WORK_GROUP: 'Work Group',
  LBL_TROUBLE_TICKET_FAC_SUBS: 'Facility/Substation',
  LBL_TROUBLE_TICKET_LINE_SUBS: 'Line/Substation',
  LBL_TROUBLE_TICKET_EQUIP_TYPE: 'Equip Type',
  LBL_TROUBLE_TICKET_TYPE: 'Ticket Type',
  LBL_TROUBLE_TICKET_DESCRIPTION: 'Description',
  LBL_TROUBLE_TICKET_AGE: 'Age',
  LBL_TROUBLE_TICKET_ASSIGNED_TO: 'Assigned To',
  LBL_SUBMITED_BY: 'Submited By',
  LBL_TROUBLE_TICKET_AUDIT_REPORT: 'Trouble Ticket Audit Report',

  LBL_APPROVE: 'Approve',
  LBL_APPROVE_REQUEST: 'Approve Request',
  LBL_REJECT: 'Reject',
  LBL_REJECT_REQUEST: 'Reject Request',

  // Step 1 LER REQUEST
  LBL_LINE_STATION: 'Line Station',
  LBL_ASSIGNEE: 'Assignee',
  LBL_CREATE_LER: 'Create LER',
  LBL_SUBMIT_LER: 'Submit LER',

  // Step 4 LER REQUEST
  LBL_CREW_INFO_NAME: 'Crew Name',
  LBL_INFO_CELLPHONE_NO: 'Cellphone Number',
  LBL_CREW_INFO_PAGER: 'Pager',
  LBL_CREW_INFO_TRUCK: 'Truck',
  LBL_REQUESTOR_NAME: 'Requestor Name',
  LBL_REQUESTOR_EMAIL: 'Requestor Email',
  LBL_INFO_CONTACT_NO: 'Contact Number',
  LBL_REQUESTOR_SUPERVISOR: 'Supervisor',
  LBL_GF_CA: 'GF/CA',
  LBL_GF_CA_CHECK_BY: 'Field Check By',

  // LER Creation Step 5
  LBL_OPERATING_NOTE: 'Operating Note',
  LBL_CANCELLATION_NOTE: 'Cancellation Note',
  LBL_DAILY_OUTAGE_REPORT_NOTE: 'Daily Outage Report Notes',
  LBL_ADD_NOTE: 'Add Note',
  LBL_REJECTION_NOTE: 'Rejection Notes',

  // LER Creation Step 3
  LBL_LER_DOCUMENTS: 'LER Documents',
  LBL_SWITCH_OUT: 'Switch Out (Minutes)',
  LBL_SWITCH_IN: 'Switch In (Minutes)',
  LBL_WORK_ORD_NO: 'Work Order No.',
  LBL_WORK_DESC: 'Work Description',
  LBL_JOB_LOC: 'Job Location',
  LBL_JOB_NOTES: 'Notes',
  LBL_RADIALIZED_SUBS: 'Radialized Subs',
  LBL_RADIALIZED_SUBSTATIONS: 'Radialized Substations',
  LBL_RIMS_PROJECT_ID: 'RIMS Project ID',
  LBL_PROJECT_ID: 'Project ID',
  LBL_RIMS_PROJECT_PHASE: 'RIMS Project Phase',
  LBL_TB_COORDINATE: 'TB Coordinates',
  LBL_GROUND_STATEMENT: 'Grounding Statement Required',
  LBL_RECALL_TIME: 'Recall Time',
  LBL_TEST_VOLTAGE: 'Test Voltage to be Applied',
  LBL_SPLIT_SAIDI_CAL: 'Split SAIDI Calculation',
  LBL_CONTINGENCY_PLAN_DEVELOPED: 'Contingency plan developed?',
  LBL_ISOLATION_POINTS: 'Isolation Points',
  LBL_WORK_PERMISSION: 'If allowed, proceed work during increment weather',
  LBL_PHASING_REQ: 'Phasing required',
  LBL_RAS_SPS: 'Affects RAS/SPS',
  LBL_JOB_ID: 'Job ID',
  LBL_DOCUMENT_NO: 'No.',
  LBL_DELETE_DOCUMENT: 'Confirm',
  LBL_OUTAGE_DATES: 'Outage Dates',

  // Authorized List
  LBL_TRN_DATE: 'Training Date',
  LBL_TRN_EXPIRY: 'Training Expiry Date',
  LBL_FIRM: 'Firm',
  LBL_TRAINER: 'Trainer',
  LBL_SUBCATEGORY: 'Subcategory',
  LBL_TEST_FAIL: 'Test Fail',
  LBL_ADD_MORE: 'Add More',
  LBL_ALL_DAY_OUTAGE: 'All Day Outage (24 HRs)',
  LBL_ADD_TRAINING: 'Add Training',
  LBL_CURRENT_AUTHORIZATION_STATUS: 'Current Authorization Status',

  // Authorized Members
  LBL_BULK_UPLOAD: 'Bulk Upload',
  LBL_ENTER_ID_NAME: 'Enter ID/Name',
  LBL_EMPLOYEE_DATA_ID: 'Employee, ID',
  LBL_COST_CENTER: 'Cost Center',
  LBL_MANAGER: 'Manager',
  LBL_FIRM_NAME: 'Firm Name',
  LBL_EMPLOYEE_NAME_INFO: 'Employee Name / Info',
  LBL_EMPLOYEE_CONTRACTOR: 'Employee/Contractor',
  LBL_AUTHORIZED: 'Authorized',
  LBL_UNAUTHORIZED: 'Unauthorized',
  LBL_AUTHORIZATION_TRAINING: 'Authorization Training',
  LBL_AUTHORIZATION_TYPE: 'Authorization Type',
  LBL_FIELD_LINE_SWITCHING: 'Field/Line Switching',
  LBL_SUBSTATION_SWITCHING: 'Substation Switching',
  LBL_SUBSTATION_ENTRY: 'Substation Entry',
  LBL_COMMENTS: 'Comments',
  LBL_STARTS: 'Starts',
  LBL_ENDS: 'Ends',
  LBL_ADD_AUTHORIZED_MEMBER: 'Add Authorized Member',

  // OC LER Request
  LBL_OC_LER_OVERVIEW: 'Overview',
  LBL_OC_LER_JOB_INFO: 'Job Info',
  LBL_OC_LER_CREW: 'Crew',
  LBL_OC_LER_AUTH_STMT: 'Auth Stmnt',
  LBL_OC_LER_ISO: 'ISO',
  LBL_OC_LER_SWITCHING: 'Switching',
  LBL_OC_LER_SYS_CHANGE: 'System Change',
  LBL_OC_LER_COSTS: 'Costs',
  LBL_OC_LER_VER_CHANGE: 'Version Change',
  LBL_OC_LER_VER_CONTROL: 'Version Control',
  LBL_OC_LER_MCC_RELAYS: 'MCC_$_Relays',
  LBL_OC_LER_MCC_SUBSTATION: 'MCC_$_Substation Construction & Maint',
  LBL_OC_LER_MCC_TCM: 'MCC_$_TCM',
  LBL_OC_LER_MCC_ADMIN: 'MCC_$_Contract Administration',
  LBL_OC_LER_MCC_OTHER: 'MCC_$_Other',
  LBL_OC_LER_ISO_RECONCILE_OUTAGES: 'Reconcile OMS Outages',
  LBL_OC_LER_ISO_NATURE_WORK: 'Nature of Work',
  LBL_OC_LER_ISO_PRIMARY_ISO_CAUSE_CODE: 'Primary ISO Cause Code',
  LBL_OC_LER_ISO_SECONDARY_ISO_CAUSE_CODE: 'Secondary ISO Cause Code',
  LBL_OC_LER_ISO_REQUIRED_ONLY_FORCED: 'Required only for FORCED outage',
  LBL_OC_LER_ISO_USE_FACILITY_MODEL: 'Use Facility Model',
  LBL_OC_LER_SYSTEM_CHANGE:
    'System Change (Email will be sent to MCC_System_Change)',
  LBL_LER_STATUS: 'LER Status',
  LBL_OMS_STATUS: 'OMS Status',
  LBL_OMS: 'OMS',
  LBL_OMS_OUTAGE: 'OMS Outage',
  LBL_OMS_ID: 'OMS ID',
  LBL_OMS_TR_ID: 'OMS TR ID(s)',
  LBL_RECONCILE_STATUS: 'Reconcile Status',
  LBL_FARFAN: 'FAN / FAR',
  LBL_FAR: 'FAR',
  LBL_FAN: 'FAN',
  LBL_SYSTEM_CHANGE_COMPLETE: 'System Change Complete',

  // OMS Switches Table
  LBL_OMS_SWITCHES_ISO_Name: 'ISO Name',
  LBL_OMS_SWITCHES_TR_NAME: 'TR Name',
  LBL_OMS_SWITCHES_TR_ID: 'TR ID',
  LBL_OMS_SWITCHES_STATE: 'State',
  LBL_OMS_SWITCHES_START_DATE_TIME: 'Start Date/Time',
  LBL_OMS_SWITCHES_END_DATE_TIME: 'End Date/Time',

  // Equipment Rating Table
  LBL_NORMAL_RATING: 'Normal Rating',
  LBL_EMERGENCY_RATING: 'Emergency Rating',
  LBL_HIGHEST_EMERGENCY_RATING: 'Highest Emergency Rating',
  LBL_START_TIME: 'Start Time',
  LBL_STOP_TIME: 'Stop Time',
  LBL_END_TIME: 'End Time',

  // OC LER Crew
  LBL_CREW_DEPARTMENT: 'Department',
  // Examples of status
  LBL_REQUESTOR_STATUS_AUTHORIZED: 'Authorized',
  LBL_REQUESTOR_STATUS_DENIED: 'Denied',

  // Trainers
  LBL_TRAINER_NAME: 'Trainer Name',
  LBL_OUTAGE_TYPE: 'Outage Type',
  LBL_ACTIVE_TRAINER: 'Active Trainers',
  LBL_INACTIVE_TRAINER: 'Inactive Trainers',

  // Trouble Jobs
  LBL_TROUBLE_JOB: 'Trouble Job',
  LBL_TROUBLE_JOBS: 'Trouble Jobs',
  LBL_CREATE_TROUBLE_JOB: 'Create Trouble Job',
  LBL_UPDATE_TROUBLE_JOB: 'Update Job',
  LBL_CREATE_STATION_ALARM_TROUBLE_JOB: 'Create Station Alarm Trouble Job',
  LBL_ETS: 'ETS',
  LBL_TROUBLE_JOB_ETS_DISPATCH: 'ETS Dispatch',
  LBL_OUT_DATE_TIME: 'Out Date & Time',
  LBL_BACK_DATE_TIME: 'Back Date & Time',
  LBL_DATE_TIME: 'Date & Time',
  LBL_TL_SUB: 'TL/Sub',
  LBL_SUB_POLE: 'Sub/Pole',
  LBL_AREA: 'Area',
  LBL_DATE: 'Date',
  LBL_DATES: 'Dates',
  LBL_TIME: 'Time',
  LBL_REMARKS: 'Remarks',
  LBL_ORDER_NUM: 'Order#',
  LBL_SEND: 'Send',
  LBL_CREATED_BY: 'Created by',
  LBL_CLOSED_BY: 'Closed by',
  LBL_ACTIVATED_BY: 'Activated by',
  LBL_CLOSED_DATE: 'Closed Date',
  LBL_CREATE_JOB: 'Create Job',
  LBL_TROUBLE_JOB_FACILITY_INFO: 'Facility Information',
  LBL_TROUBLE_JOB_INFO: 'Trouble Job Information',
  LBL_TROUBLE_JOB_BEGIN_DATE: 'Begin Date',
  LBL_TROUBLE_JOB_END_DATE: 'End Date',
  LBL_TROUBLE_JOB_PRIMARY: 'Primary Cause',
  LBL_TROUBLE_JOB_SECONDARY: 'Secondary Cause',
  LBL_TROUBLE_JOB_DESC: 'Description',
  LBL_TROUBLE_JOB_STATUS: 'Status',
  LBL_TROUBLE_JOB_EMAIL_NOTIFY: 'Email Notify',
  LBL_TROUBLE_JOB_PARTICIPANTS: 'Participants',

  // Substation Entry
  LBL_GRID_OPS_HOME: 'Grid Ops Home',
  LBL_SUBSTATION_LIST: 'Substation List',
  LBL_TODAYS_LOG: "Today's Log",
  LBL_SUBSTATION_ADDRESS: 'Address',
  LBL_SUBSTATION_PHONE_CODE: 'Area Code (xxx)',
  LBL_SUBSTATION_PHONE: 'Phone Number (xxx-xxxx)',
  LBL_SUBSTATION_DETAILS: 'Substation Details',
  LBL_TIME_IN_HOUR: 'Time In',
  LBL_TIME_OUT_HOUR: 'Time Out',

  // Real Log Entry
  LBL_SUBS_INTERRUPTED: 'Subs Interrupted',
  LBL_LINE_SUBSTATION_SELECT: 'Line / Substation',
  LBL_USER_TYPE: 'User Type',
  LBL_CATEGORY: 'Category',
  LBL_DATE_TIME_OCURRED: 'Date and Time Ocurred',
  LBL_DATE_TIME_RESTORED: 'Date/Time Restored',

  // Expiring Trainings
  LBL_ADD_AUTHORIZATION: 'Add Authorization',
  LBL_TRAINING_DATE: 'Training Date',
  LBL_EMAIL: 'Email',

  // OMS Outage Data
  LBL_ISO: 'ISO Name',
  LBL_TR_NAME: 'TR Name',
  LBL_TR_ID: 'TR ID',
  LBL_SWITCH_MODELING_COMPLETE: 'Switch Modeling Complete',
  LBL_TIME_HH: 'Time (HHMM)',
  LBL_END_DATE: 'End Date',
  LBL_EMERGENCY_RETURN_TIME: 'Emergency Return Time',
  LBL_PARTICIPANT_OUTAGE_ID: 'Participant Outage ID',
  LBL_NATURE_OF_WORK: 'Nature of Work',
  LBL_RAS_SPS_OOS: 'RAS/SPS Out of Service',
  LBL_RAS_SPS_RR: 'RAS/SPS Reduced Redundancy',
  LBL_PROTECTION_ZONE: 'Protection Zone',
  LBL_EMS_ICCP_OUTAGES: 'EMS/ICCP Outages',
  LBL_PRIMARY_OUTAGE_CAUSE_CODE: 'Primary Outage Cause Code',
  LBL_SECONDARY_OUTAGE_CAUSE_CODE: 'Secondary Outage Cause Code',
  LBL_SHORT_DESCRIPTION_CATEGORY: 'Short Description Category',
  LBL_SHORT_DESCRIPTION: 'Short Description',
  LBL_REQUIRED_FORCED_OUTAGE: '(Required for FORCED Outage)',
  LBL_OMS_OUTAGE_ID: 'OMS Outage ID',
  LBL_OUTAGE_ID: 'Outage ID',

  // Roles
  LBL_ROLES: 'Role Administration',
  LBL_DROP_ALL_ROLES: 'Drop All Roles',
  LBL_DOWNLOAD_EMAIL_LIST: 'Download Email List',
  LBL_DOWNLOAD_EXCEL: 'Export to Excel',
  LBL_DOWNLOAD_PDF: 'Download PDF',
  LBL_SAVE_AS_PDF: 'Save as PDF',
  LBL_ROLE_EMAIL: 'Email',
  LBL_ROLE_HR_STATUS: 'HR Status',
  LBL_ROLE_STATUS: 'Role Status',
  LBL_ROLE_TITLE: 'Title',
  LBL_ROLES_TITLES: 'Roles',
  LBL_ROLES_FILTER: 'Role',
  LBL_ROLES_ASSIGMENT: 'Roles Assigment',
  LBL_KEYS: 'Keys',
  LBL_AUTH_LIST: 'Auth List',
  LBL_SUBSTATION_ENTRY_LOG: 'Substation Entry Log',
  LBL_OUTAGE_PLANNING: 'Outage Planning',
  LBL_REAL_TIME_LOG: 'Real-time Log',
  LBL_BPOR_REPORT: 'BPOR Report',
  LBL_GRANT_ACCESS: 'Grant Access',

  // Outages
  LBL_OUTAGE_REQUESTOR_NAME: 'Requestor Name',
  LBL_OUTAGE: 'Outage',
  LBL_START_DATE: 'Start Date',
  LBL_STOP_DATE: 'Stop Date',
  LBL_DAYS: 'Days',
  LBL_OUTAGE_STATUS: 'Outage Status',
  LBL_CATEGORY_TYPE: 'Category Type',
  LBL_CANCEL_NOTES: 'Cancel Notes',
  LBL_WORK_ORDER_NUMBER: 'Work Order Number',
  LBL_OUTAGES_CANCEL_NOTES: ' Only Show Outages with Cancel Notes',
  LBL_OUTAGES_STATUS: 'Status',
  LBL_OUTAGES_OUTAGE_CATEGORY: 'Outage Catetgory',
  LBL_OUTAGES_CREW_NAME: 'Crew Name',
  LBL_OUTAGES_EQUIP_TYPE: 'Equipment Type',
  LBL_OUTAGES_KV: 'KV',
  LBL_OUTAGES_FACILITY_NAME: 'Facility Name',
  LBL_OUTAGES_SUBSTATION: 'Substation',
  LBL_OUTAGES_RECALL: 'Recall',
  LBL_OUTAGES_END_DATE: 'End Date',
  LBL_OUTAGES_OUTAGE: 'Outage',
  LBL_OUTAGES_RIMS_PROJECT_ID: 'RIMS Project ID',
  LBL_OUTAGES_RIMS_PROJECT_PHASE: 'RIMS Project Phase',
  LBL_OUTAGES_WORK_DESCRIPTION: 'Work Description',
  LBL_OUTAGES_SHORT_DESCRIPTION_CATEGORY: 'Short Description Category',
  LBL_OUTAGES_SHORT_DESCRIPTION_DETAIL: 'Short Description Detail',
  LBL_OUTAGES_WORK_ORDER_NUMBER: 'Work Order Number',

  // Contractor Firm
  LBL_CONTRACTOR_FIRM_SHORT_NAME: 'Firm Short Name',
  LBL_CONTRACTOR_FIRM_FULL_NAME: 'Firm Full Name',
  LBL_CONTRACTOR_FIRM_CONTACT: 'Contact Name',
  LBL_CONTRACTOR_FIRM_AREA_CODE: 'Area Code',
  LBL_CONTRACTOR_FIRM_PHONE_NUMBER: 'Phone Number',
  LBL_LER: 'LER',
  LBL_REQUESTOR: 'Requestor',
  LBL_CANCEL_REASON: 'Cancel Reason',
  LBL_CANCEL_TYPE: 'Cancel Type',
  LBL_REASON: 'Reason',
  LBL_CANCEL_BY: 'Cancel By',
  LBL_CANCEL_DATE: 'Cancel Date',
  LBL_CANCELLED_BY: 'Cancelled By',
  LBL_EDIT_NOTE: 'Edit Note',
  LBL_DELETE_NOTE: 'Delete Note',
  LBL_AUTH: 'Auth',
  LBL_DAILY_OUTAGE_REPORT_NOTES: 'Daily Outage Report Notes',

  // Email Link Page
  LBL_JOB_INFO_JOB_ID: 'Job ID',
  LBL_JOB_INFO_WORK_ORDER: 'Work Order',
  LBL_JOB_INFO_WORK: 'Work Description',
  LBL_JOB_INFO_NOTES: 'Notes',
  LBL_JOB_INFO_RADIALIZED: 'Radialized Subs',
  LBL_JOB_INFO_RIMS_ID: 'RIMS Project ID',
  LBL_JOB_INFO_RIMS_PHASE: 'RIMS Project Phase',
  LBL_JOB_INFO_TB: 'TB Coordinate',
  LBL_JOB_INFO_GROUNDED: 'Grounded Statement Required',
  LBL_JOB_INFO_RECALL: 'Recall Time',
  LBL_JOB_INFO_TEST_VOLTAGE: 'Test Voltage to be Applied',
  LBL_JOB_INFO_PENDING_APPROVAL: 'Pending Email Approval {0}/{1}',
  LBL_CAISO_PENDING: 'Pending Request',
  LBL_CAISO_PENDING_SUBMITTAL: 'Pending CAISO',
  LBL_CAISO_FAILED_SUBMITTAL: 'Failed CAISO',
  LBL_SORT_PENDING_SUBMITTAL: 'Pending SORT',
  LBL_SORT_FAILED_SUBMITTAL: 'Failed SORT',
  LBL_JOB_INFO_ISOLATION: 'Isolation Points',
  LBL_FACILITY_INFO_FACILITY_TYPE: 'Facility Type',
  LBL_FACILITY_INFO_KV: 'KV',
  LBL_FACILITY_INFO_EQUIPMENT: 'Equipment',
  LBL_FACILITY_INFO_LINE_STATION: 'Line/Station',
  LBL_FACILITY_INFO_AUTHORIZATION: 'Authorization',
  LBL_APPROVAL_STATUS_APPROVER: 'Approver',
  LBL_APPROVAL_STATUS_STATUS: 'Status',
  LBL_APPROVAL_STATUS_DATE_REQUESTED: 'Date Requested',
  LBL_APPROVAL_STATUS_COMMENTS: 'Comments',

  LBL_EMAIL_NOTIFICATIONS: 'Email Notifications',
  LBL_SEND_CAISO: 'Send to CAISO',
  LBL_ACTIVITY: 'Activity',

  LBL_CHECK_OUT: 'Check out',
  // Rejected Report
  LBL_OVERVIEW: 'Overview',
  LBL_SUMMARY: 'Summary',
  LBL_REJECTED_BY: 'Rejected By',
  LBL_REJECTOR: 'Rejector',
  LBL_REJECTED_DATE: 'Rejected Date',
  LBL_NUM_LER_SUBMITTED: 'No. LERs Submitted',
  LBL_LER_REJECTION: 'LERs with more than one rejection',
  LBL_NUM_REJECTION: 'No. of Rejections',
  LBL_REASON_OF_REJECTION: 'Reason of rejection',

  // LER update
  LBL_REASON_OF_UPDATE: 'Reason of update',

  LBL_EMAIL_BODY: 'Email Body',

  LBL_FLAG_FOR_FOLLOW_UP: 'Flag For Follow Up',
  LBL_SKIP: 'Skip',
  LBL_SEND_EMAIL_NEXT: 'Send Email & Next Step',
  LBL_SEND_CAISO_FINISH: 'Send to CAISO & Finish',
  LBL_SEND_UPDATE_CAISO_FINISH: 'Send update to CAISO & Finish',
  LBL_SEND_UPDATE_CAISO: 'Send update to CAISO',
  LBL_GO_TO_ISO: 'Go to ISO',
  LBL_SEND_LATER: 'Send Later',
  LBL_FORCED_OUTAGE_NOTICE: 'Pending Action: Send Forced Outage Email',

  // TNA
  LBL_TNA_STATUS: 'Status',
  LBL_TNA_EQUIPMENT_TYPE: 'Equipment Type',
  LBL_TNA_VOLTAGE: 'Voltage',
  LBL_TNA_EQUIPMENT: 'Equipment',
  LBL_TNA_RECALL_TIME: 'Recall Time',
  LBL_TNA_START_DATE: 'Start Date',
  LBL_TNA_END_DATE: 'End Date',
  LBL_TNA_START_TIME: 'Start Time',
  LBL_TNA_END_TIME: 'End Time',
  LBL_TNA_DAYS: 'Days',
  LBL_TNA_AUTH_TYPE: 'Auth Type',
  LBL_TNA_WORK_DESCRIPTION: 'Work Description',
  LBL_TNA_NATURE_OF_WORK: 'Nature of Work',

  // LER Change Request
  LBL_CHANGE_REQUEST: 'Change Request',
  LBL_LER_VIEW_CHNG_REQ: 'View Change Request',
  LBL_LER_REQUEST_WITHDRAW: 'Withdraw Request',
  LBL_LER_REQUEST_WITHDRAW_CHANGE: 'Withdraw Change Request',
  LBL_CHANGE_REQUEST_APPROVE: 'Approve Request',
  LBL_CHANGE_REQUEST_REJECT: 'Reject Request',
  LBL_CHANGE_REQUEST_FIELD: 'Fields',
  LBL_CHANGE_REQUEST_VERSION: 'Verion {0} (Current Version)',
  LBL_LER_BACK_TO_VIEW: 'Back to LER View',
  LBL_LER_CLONE: 'Clone LER',

  LBL_LER_CREATE: 'Create LER',
  LBL_VERSION_NO: 'Version No',
  LBL_VERSION_NO_HEADER: 'Version {0}',

  // Application Settings
  LBL_PROPERTY: 'Property',
  LBL_VALUES: 'Values',
  LBL_VALUE: 'Value',

  // Suspension List History
  LBL_AUTH_TYPE: 'Auth Type',
  LBL_EVENT: 'Event',
  LBL_EVENT_TIME: 'Event Time',

  // Training History
  LBL_TRAINING_EXPIRATION_DATE: 'Training Expiration Date',
  LBL_FAILED_TEST: 'Failed Test',

  LBL_ASSIGN: 'Assign',
  LBL_UNASSIGNED: 'Unassigned',

  LBL_CHANGE_REQUEST_CANCEL: 'Request to Cancel LER',

  // LER ISO
  LBL_LER_ISO_CAISO_EQUIP: 'CAISO Equipment ID(s)',

  LBL_DISTRIBUTION_LIST: 'Distribution List',
  LBL_SEND_COST_EMAIL: 'Send Cost Emails',
  // Approval Notification Email
  LBL_EMAIL_DATE_SENT: 'Date Sent',
  LBL_EMAIL_APPROVAL_STAT: 'Approval Status',
  LBL_ADD_EMAILS: 'Add Email(s)',
  LBL_SUB_COUNT_FILE_ERRORS: 'There are error/s with your uploaded file',

  // OMS Audit Report
  LBL_UPDATE_DATE: 'Date',
  LBL_UPDATER: 'User',
  LBL_EVENT_VALUE: 'Event Value',

  // Daily Report
  LBL_OUT_TIME: 'Out Time',
  LBL_IN_TIME: 'In Time',
  LBL_DAILY_REPORT_SYSTEM_CHANGES: 'System Changes',

  // OMS Request
  LBL_PLANNED_DATE_TIME: 'Planned Date/Time',
  LBL_TRANSMISSION_REGISTRY_IDS: 'Transmission Registry IDs',
  LBL_EMERGENCY_RETURN: 'Emergency Return',
  LBL_OUTAGE_WARNINGS: 'Outage Warnings',
  LBL_WARNING_ID: 'Warning ID',
  LBL_WARNING_TYPE: 'Warning Type',
  LBL_WARNING_TEXT: 'Warning Text',
  LBL_ACKNOWLEDGE_WARNING: 'Acknowledge Warning',
  LBL_SEND_TO_OMS_REFRESH: 'Send to OMS / Refresh',
  LBL_ERROR_STATUS: 'Error Status',
  LBL_SUCCESS_STATUS: 'Success Status',
  LBL_OVERALL_SUBMITTAL_STATUS_FAILURE: 'Overall New Submittal Status Failure',
  LBL_OVERALL_SUBMITTAL_STATUS_SUCCESS: 'Overall New Submittal Status Success',

  // Related LER
  LBL_RELATED_LER_NO: 'LER#',

  LBL_PENDING_CANCEL: 'Pending Cancel',
  LBL_LER_WITH_MORE_ONE_REJECTION: 'LERs with 1 or More Rejections',

  LBL_SYSTEM_CHANGE_SUMMARY: 'System Change Summary',
  LBL_AUTHOR: 'Author',
  LBL_IN_SERVICE_DATE: 'In Service Date',
  LBL_START_DATE_SYSCHG: 'Start Date',
  LBL_SYSTEM_CHANGE: 'System Change',
  LBL_MORE: 'More',

  LBL_BYPASS_EMAIL_APPROVAL: 'Bypass email approval process and Schedule LER',
  LBL_NO_EMAIL_APPROVAL:
    'No email approvals required. Proceed with Schedule LER.',
  LBL_NO_EMAIL_APPROVAL_SCHEDULED:
    'No email approvals required. LER already scheduled.',

  // Categories List
  LBL_CATEGORY_NAME: 'Category Name',
  LBL_DESCRIPTIONS: 'Descriptions',
  LBL_DESCRIPTION_NAME: 'Description Name',
  LBL_ADD_NEW_DESCRIPTION: 'Add New Description',
  LBL_EDIT_DESCRIPTION: 'Edit Description',
  LBL_NO_DESCRIPTION_ADDED: 'No descriptions added',

  LBL_NOTICE: 'NOTICE:',
  LBL_EXPIRED: 'Expired',
  LBL_ACTIVE: 'Active',
  LBL_INACTIVE: 'Inactive',
  LBL_SUSPENDED: 'Suspended',
  LBL_RESET: 'Reset',

  // ETS
  LBL_SHOW_COMPLETED: 'Show Completed',
  LBL_SWITCHING_SEND: 'Send',
  LBL_SWITCHING_CANCEL: 'Cancel',
  LBL_SWITCHING_START_TIME: 'Start Time',
  LBL_SWITCHING_END_TIME: 'End Time',

  LBL_ACTION_PENDING_BY: 'Action Pending By',
  LBL_OUTAGE_TIME: 'Time',
};
